import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';

import DrinkCard from '../components/DrinkCard'
import {DrinkData} from '../data/DrinkData.js'
import MenuAppBar from '../components/AppBar'
import Grow from '@material-ui/core/Grow';

import getFirebase from '../utils/firebase';

import {navigate} from "gatsby"

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from "../components/layout";

import Typography from '@material-ui/core/Typography';

import SEO from "../components/seo"

function IndexPage({location}) {

  const firebase = getFirebase();

  // State
  const [isLoaded, setIsLoaded] = useState(false);
  const [userFavourites, setUserFavourites] = React.useState([]);
  const [drinks, setDrinks] = React.useState(null);

  const activeDrink = DrinkData[Math.floor(Math.random() * DrinkData.length)];

  useEffect(() => {
    if (!firebase)
      return;
    if (isLoaded)
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          const db = firebase.firestore();
          let docRef = db
            .collection("users")
            .doc(user.uid);
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                setUserFavourites(doc.data()['favourites'])
                setIsLoaded(true);
              } else {
                // doc.data() will be undefined in this case
                navigate('/signedin')
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
            let drinksRef = db
              .collection("drinks")
              .doc('classics')
            drinksRef
              .get()
              .then(function(doc) {
                if (doc.exists) {
                  setDrinks(doc.data())
                  // console.log(drinks)
                  // console.log(drinks['1'].likedBy.length)
                  setIsLoaded(true);
                } else {
                  // doc.data() will be undefined in this case
                  navigate('/home')
                }
              })
              .catch(function(error) {
                console.log("Error getting document:", error);
              });
        } else {
          navigate('/')
        }
      });
  });

  const handleBackClick = () => {
    navigate('/home')
  }

  const finalPage = isLoaded
    ? <Layout>
        <SEO title='Random'/>
        <MenuAppBar/>
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            minHeight: '100vh',
            padding: '80px 0px 30px',
            // backgroundColor: 'red'
          }}>
          <Grow in="in">
            <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                // paddingTop: '5vh'
              }}>
              <Typography style={{
                  fontFamily: 'Work Sans',
                  fontWeight: '800',
                  fontSize: '38px',
                  backgroundColor: '',
                  paddingBottom: '20px'
                }}>Just for You</Typography>
                <DrinkCard isFavourite={userFavourites
                    ? userFavourites.includes(activeDrink.id)
                    : false} drinkData={activeDrink} likes={drinks
                    ? drinks[activeDrink.id].likedBy.length
                    : 0}/>
              <Button onClick={handleBackClick} style={{
                  marginTop: '25px'
                }} size="large" color="primary">Back Home</Button>
            </Grid>
          </Grow>
        </Grid>
      </Layout>
    : <div><MenuAppBar/>
      <Grid container="container" direction="row" justify="center" alignItems="center" style={{
          minHeight: '100vh',
          width: '100vw'
        }}><CircularProgress/></Grid>
    </div>

  // Render
  return (finalPage)
}

export default IndexPage
